// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'AvernirNext';
    src: url('../../fonts/AvenirNext-Regular.woff2') format('woff2'),
    url('../../fonts/AvenirNext-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'AvernirNext';
    src: url('../../fonts/AvenirNext-Medium.woff2') format('woff2'),
    url('../../fonts/AvenirNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'AvernirNext';
    src: url('../../fonts/AvenirNext-Bold.woff2') format('woff2'),
    url('../../fonts/AvenirNext-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'AbrilDisplay';
    src: url('../../fonts/AbrilDisplay-Bold.woff2') format('woff2'),
    url('../../fonts/AbrilDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


$headings-font: 'AbrilDisplay', sans-serif;
$body-font: 'AvernirNext', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 1.95rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    line-height: (25/19.5);
    font-family: $body-font;
    position: relative;
}

p {
    margin-bottom:2.5rem;

    &:last-child{
        margin-bottom: 0;
    }
}

.line-above{
    &:before{
        content:'';
        display: block;
        width:100px;
        height: 2px;
        background: $primary;
        margin: 3rem 0;

        .text-center &{
            margin-left: auto;
            margin-right: auto;
        }
    }

    &:first-child{
        &:before{
            margin-top: 0;
        }
    }
}

.line-below{
    &:after{
        content:'';
        display: block;
        width:100px;
        height: 2px;
        background: $primary;
        margin: 3rem 0;

        .text-center &{
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 2rem;
    font-family: $headings-font;
    font-weight: bold;
    text-transform: capitalize;

    br{
        display: none;

        @include media-breakpoint-up(xl){
            display: block;
        }
    }
}

h1, .h1 {
    font-size: 4rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    line-height: (55.5/45);

    @include media-breakpoint-up(xl){
        font-size: 4.5rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }
}

h2, .h2 {
    font-size: 3.5rem;  //Laptop: 46px, Ipads: 41.4px, Mobiles: 36.8px
    line-height: (48/40);

    @include media-breakpoint-up(xl){
        font-size: 4rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }
}

h3, .h3 {
    font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px
}

h4, .h4 {
    font-size: 2.4rem;  //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
}

h5, .h5 {
    line-height: (26/19.5);
    font-family: $body-font;
    font-weight: bold;
    font-size: 1.95rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
}

h6, .h6 {
    font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    font-size: 1.9rem;
    letter-spacing: .1em;
    font-weight: 500;
    color:$primary;

    @include media-breakpoint-up(md){
        letter-spacing: .18em;
    }
}

.subheadline {
}