/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


// Custom Slick Styles

// Arrows
.slick-arrow {
    position: absolute;
    top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px; 

    img{
        width:100%;
    }

    &.prev,
    &.prev-arrow {
        left: 15px;
    }
    &.next,
    &.next-arrow {
        right: 15px;
    }

    @include media-breakpoint-up(md){
        width: 50px; 

        &.prev,
        &.prev-arrow {
            left: 0;
        }
        &.next,
        &.next-arrow {
            right: 0;
        }
    }

    @include media-breakpoint-up(lg){
        width: 70px;
    }
}


// Dots
.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0 0;
    list-style-type: none;

    li {
        margin: 0 0.3rem;
    }

    button {
        display: block;
        width: 2rem;
        height: 2rem;
        padding: 0;
        margin:0;
        border: 1px solid $dark;
        border-radius: 50%;
        background-color: #FFF !important;
        text-indent: -9999px;
    }

    li.slick-active button {
        background-color: $primary !important;
    }
}