
ul, ol {
	&.styled-list {
		margin: 0 auto;
		padding: 0;
		list-style: none;
		max-width: 590px;

		p {
			strong {
				text-transform: capitalize;
				display: block;
			}
		}

		li {
			position: relative;
            margin-bottom: 2.5rem;
			padding-left: 45px;
			display: block;

			&::before {
				content:'';
				position: absolute;
				top: 5px;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				background: url('../images/icon-tick-cover.png') no-repeat center/contain;
			}

			@include media-breakpoint-up(md) {
				padding-left: 55px;

				&::before {
					width: 37px;
					height: 37px;
				}
			}
        }
	}
}

