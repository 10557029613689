body.ebook-page {
	.form-container{
		padding: 0 5px;
	}

	#section-1{
		overflow: hidden;
		padding: 110px 0 70px;

		@include media-breakpoint-up(md){
			padding: 170px 0 120px;
		}

		@include media-breakpoint-up(lg){
			padding: 180px 0px 160px;
		}

		@include media-breakpoint-up(xl){
			padding: 160px 0 170px;
		}

		.ebook-image{
			width:100%;
			position: relative;
			left: 9%;
			margin: 3rem auto;
		}

		form{
			margin: 1rem 0;
			max-width: 100%;
		}

		p.subheadline{
			line-height: (26/19);
		}

		@include media-breakpoint-up(sm){
			.ebook-image{
				left: 5.5%;
			}
		}

		@include media-breakpoint-up(md){
			.ebook-image{
				width: 60%;
				left:auto;
			}
		}

		@include media-breakpoint-up(lg){
			.ebook-image{
				width:500px;
				right: -40px;
				margin:0;
			}
			form{
				max-width: 500px;
			}
		}

		@include media-breakpoint-up(xl){
			.ebook-image{
				width:586px;
				max-width: none;
				right: -78px;
			}
		}
	}

	#section-7{
		p{	
			max-width: 590px;
			margin-left: auto;
			margin-right: auto;
		}

		.styled-list{
			margin: 3.5rem auto;
		}
	}

	#section-14{
		.btn-section{
			max-width: 100%;
			margin: 3px 0 0;
		}
	}

}