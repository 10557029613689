.step-row{
    margin: 2rem -15px;

    @include media-breakpoint-up(md){
        margin-top: 4.5rem;
        margin-bottom: 3rem;
    }

    .step {
        height: 100%;
        background: white;
        padding: 30px 20px;

        @include media-breakpoint-up(md){
            padding: 50px 30px;
        }

        @include media-breakpoint-up(xl){
            padding: 65px 30px 70px;
            padding-right: 35px;
        }
    }

    .step-col{
        margin: 0 0 15px;

        @include media-breakpoint-up(md){
            margin: 0 0 30px;
        }
    }

    .step-title{
        margin: 0 0 2.5rem;
        text-align: center;

        @include media-breakpoint-up(md){
            text-align: left;
        }
    }

    .step-number {
        background: $primary;
        color: $dark;
        width: 80px;
        height: 80px;
        line-height: 85px;
        border-radius: 50%;
        font-family: $headings-font;
        font-size: 4rem;
        margin: 0 auto 2.8rem;

        @include media-breakpoint-up(md){
            width: 90px;
            height: 90px;
            line-height: 100px;
            font-size: 4rem;
        }

        @include media-breakpoint-up(xl){
            width: 110px;
            height: 110px;
            line-height: 120px;
            font-size: 4.8rem;
        }
    }
}