#section-1{
	background:#000;
	padding: 110px 0 90px;

	@include media-breakpoint-up(md){
		padding: 170px 0 160px;
	}

	@include media-breakpoint-up(lg){
		padding: 190px 0 200px;
	}

	@include media-breakpoint-up(xl){
		padding: 215px 0 235px;
	}

	.background-image-container{
		z-index: 2;
		opacity: .46;
	}

	.container{
		position: relative;
		z-index: 4;

		h1,.subheadline{
			color:#FFF;
		}
	}

	&:before{
		content:'';
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 3;
		background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 56%);
	}

	.eyebrow{
		margin: 0 0 1.5rem;
	}
	h1{
		margin: 0 0 1.8rem;
	}
	.subheadline{
		margin: 0 0 3.6rem;
	}
}

#section-2{
	overflow: visible;
	position: relative;
	z-index: 5;
	padding:0;

	.slider{
		padding: 40px 20px 30px;
		margin-top: -35px;
		background:#FFF;
  		box-shadow: 0px 1px 34px 0px rgba(138, 138, 138, 0.18);

		@include media-breakpoint-up(md){
			padding: 50px 30px 40px;
		}

		@include media-breakpoint-up(lg){
			padding: 50px 30px;
			margin-top: -55px;
		}

		@include media-breakpoint-up(xl){
			padding: 60px 45px;
		}

  		.slide{
  			display: flex;
  			flex-direction: row;
  			align-items: center;
  			justify-content: center;
  			white-space: nowrap;

  			img{
  				margin-right: 14px;
  			}

  			p{
  				font-size: 1.7rem;

  				strong{
  					font-family: $headings-font;
  					font-size: 2rem;
  					color:$dark;
  					display: block;
  				}

				@include media-breakpoint-up(xl){
  					font-size: 1.85rem;

  					strong{
  						font-size: 2.25rem;
  					}
				}
  			}
  		}

  		.slick-dots{
  			margin: 3rem 0 0;
  		}
	}
}

#section-3{
	@include media-breakpoint-up(xl){
		padding-bottom: 160px;

		.copy-col{
			padding-left: 70px;
		}
	}
}

#section-4{
	@include media-breakpoint-up(xl){
		padding: 100px 0 120px;

		.copy-col{
			padding: 0 35px;
		}
	}

	.brandmark{
		width: 40px;
		margin: 0 auto 15px;
	}

	.warnings{
		width: 100%;
		margin: 1.5rem 0 2.5rem;
	
		@include media-breakpoint-up(lg){
			margin: -8% 0 2%;
		}

		@include media-breakpoint-up(xl){
			width: 1204px;
			max-width: none;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			margin: -100px 0 40px;
		}
	}
}

#section-5{
	.image-block{
		width:100%;
		max-width: 890px;
		margin: 2rem auto 4.5rem;
  		border: 5px solid #FFF;
 		box-shadow: 0px 1px 34px 0px rgba(138, 138, 138, 0.18);

 		img{
 			display: block;
 			width:100%;
 		}
	}
}

#section-6{
	background: #000;

	@include media-breakpoint-up(xl){
		padding: 130px 0;

		.copy-col{
			padding-right: 45px;
		}
	}


	h2,p{
		color:#FFF;
	}

	.image-block{
		position: relative;
		width: 90%;
		margin: 6% auto 14%;

		@include media-breakpoint-up(sm){
			width: 80%;
		}

		@include media-breakpoint-up(lg){
			width: 100%;
			margin: 0 auto;
		}

		&:before{
			content:'';
			position: absolute;
			width: 100%;
			height: 98%;
			background: rgba(255,255,255,.21);
			top: -7%;
			right:-7%;
			z-index: 1;
		}

		img{
			width:100%;
			display: block;
			z-index: 2;
			position: relative;
		}

		&__text{
			background: #FFF;
			position: absolute;
			z-index: 3;
			bottom:-25px;
			left: 50%;
			width: calc(100% - 50px);
			transform: translateX(-50%);
			text-align: center;
			padding: 20px 20px;

			@include media-breakpoint-up(md){
				padding: 28px 20px;
				width: calc(100% - 100px);
			}

			p{
				color:#000;
				font-size: 1.85rem;
				line-height: (25/18.5);

				br{
					display: none;

					@include media-breakpoint-up(xl){
						display: block;
					}
				}
			}
		}
	}
}

#section-7{
	@include media-breakpoint-up(xl){
		padding: 100px 0 120px;
	}

	.brandmark{
		width: 40px;
		margin: 0 auto 15px;
	}

	.styled-list{
		margin: .5rem auto 3.5rem;

		@include media-breakpoint-up(md){
			margin: 1.5rem auto 4rem;
		}
	}
}

#section-8{
	@include media-breakpoint-up(xl){
		padding: 150px 0 150px;
	}

	&:before{
		content:'';
		background: rgba(0,0,0,.7);
		position: absolute;
		left:0;
		top:0;
		width:100%;
		height: 100%;
		z-index: 1;

		@include media-breakpoint-up(md){
			background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 63%,rgba(0,0,0,1) 100%);
		}
	}

	.background-image-container{
		z-index: 0;

		img{
			object-position: left;
		}

		@include media-breakpoint-up(md){
			img{
				object-position: center;
			}
		}
	}

	.container{
		position:relative;
		z-index: 2;
	}

	p{
		color:#FFF;
		margin: 0 0 3rem;

		@include media-breakpoint-up(xl){
			font-size: 2.25rem;
		}
	}

	.quotemark{
		width: 25px;
		display: block;
		margin: 0 0 8px;
	}

	.fivestars{
		width: 130px;
	}

	@include media-breakpoint-up(md){
		.quotemark{
			width: 30px;
		}

		.fivestars{
			width: 170px;
		}
	}

	@include media-breakpoint-up(lg){
		.quotemark{
			width: 38px;
		}

		.fivestars{
			width: 215px;
		}
	}
}

#section-9{
	@include media-breakpoint-up(xl){
		padding: 200px 0 215px;
	}

	.copy-col{
		@include media-breakpoint-up(xl){
			padding-right: 55px;
			padding-left:0;
		}
	}
}

#section-10{
	overflow: visible;
	padding-top:20px;

	@include media-breakpoint-up(xl){
		padding-top:0;
		padding-bottom: 160px;

		.copy-col{
			padding-left: 70px;
			padding-right: 25px;
		}
	}
}

#section-11{
	background: #2d2d30;

	@include media-breakpoint-up(xl){
		padding-top: 130px;
		padding-bottom: 150px;
	}

	h2{
		color:#FFF;
	}
}

#section-12{
	@include media-breakpoint-up(xl){
		padding-bottom: 110px;
	}

	.guarantee{
		width: 200px;
		margin: 0 auto 3rem;

		@include media-breakpoint-up(md){
			width: 314px;
		}
	}

	p{
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}
	.btn-section{
		margin-top: 3.5rem;
	}
}

#section-13{
	background:#000;

	@include media-breakpoint-up(xl){
		padding: 135px 0 120px;
	}

	.background-image-container{
		z-index: 1;
		opacity: .25;
	}

	.container{
		position: relative;
		z-index: 2;

		p,h2{
			color:#FFF;

			strong{
				display: block;
				margin-top: 3px;
				color:$primary;
			}
		}

		.slide{
			p{
				max-width: 80%;
				margin: 0 auto;
				text-align: center;

				@include media-breakpoint-up(md){
					max-width: 95%;
				}
			}
		}
	}
}

#section-14{
	@include media-breakpoint-up(xl){
		padding: 100px 0;
	}

	.background-image-container{
		top: auto;
		bottom:0;
		max-height: 1043px;

		img{
			object-position: top;
			object-fit: cover;
		}
	}

	@media screen and (min-width: 1701px){
		padding-bottom: 900px;
	}

	@media screen and (max-width: 1700px){
		padding-bottom: 52vw !important;	

		.background-image-container{
			height: 55vw;
			max-height:none;
		}
	}

	.starterpack{
		width: 726px;
		max-width: 100%;
		margin: 3.5rem auto 3.2rem;
		display: block;
	}

	.styled-list{
		margin: 3rem auto;
	}
}

.imageleft{
	position: relative;
	z-index: 0;
	width: 75%;
	margin: 2% auto 13%;

	@include media-breakpoint-up(lg){
		width: 100%;
		margin: 0 auto;
	}

	&:before{
		content:'';
		position: absolute;
		width: 100%;
		height: 98%;
		background: #faf9f6;
		bottom: -8%;
		left:-8%;
		z-index: 2;
	}

	.iconleft{
		position: absolute;
		width: 31%;
		left: -13%;
		top: -10%;
		z-index: 1;
	}

	img:not(.iconleft){
		width: 100%;
		display: block;
		position: relative;
		z-index: 3;
  		box-shadow: 0px 1px 34px 0px rgba(138, 138, 138, 0.18);
	}
}


.imageright{
	position: relative;
	z-index: 0;
	width: 75%;
	margin: 2% auto 13%;

	@include media-breakpoint-up(lg){
		width: 100%;
		margin: 0 auto;
	}

	&:before{
		content:'';
		position: absolute;
		width: 100%;
		height: 98%;
		background: #faf9f6;
		bottom: -8%;
		right:-8%;
		z-index: 2;
	}

	.iconright{
		position: absolute;
		width: 31%;
		right: -13%;
		top: -10%;
		z-index: 1;
	}

	img:not(.iconright){
		width: 100%;
		display: block;
		position: relative;
		z-index: 3;
  		box-shadow: 0px 1px 34px 0px rgba(138, 138, 138, 0.18);
	}
}
