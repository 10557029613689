footer {
    background: rgba(0,0,0,.7);
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    padding: 5px 0;
   	font-size: 1.7rem;
    z-index: 10;
    
    p a ,
    p span{
    	font-weight: 700;
        color:$primary;
    }
}