header {
    padding: 20px 15px;
    background-color: transparent;
    transition: background-color 0.4s ease, padding .4s ease;
    
    &.scrolled {
        padding: 20px 15px;
        background: #000;
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
    }

    .container-fluid{
        max-width: 1370px;
    }

    @include media-breakpoint-up(md) {
        padding: 30px 15px;
    }

}

.header-logo img {
    transition: width 0.4s ease;

    // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
    // filter: invert(1);

    // Default to small scrolled size on mobile 

    // Turn on the header resize on desktop
    width: 80px;

    @include media-breakpoint-up(md) {
        width: 120px;
    }

    @include media-breakpoint-up(lg) {
        width: 134px;
    }
    .scrolled & {
        filter: none;
        
        // Turn on the header resize on desktop
        @include media-breakpoint-up(md) {
            width: 120px;
        }
    }
}

.header-phone {
    line-height: 1.2; 
    color:#FFF;

    &:hover{
        color:#FFF;
        text-decoration: none;
    }

    .phone-icon {
        @include media-breakpoint-up(md) {
            margin-right: 14px;
        }

        img,
        svg {
            max-width: 24px;
            width: 100%;
        }
    }

    // Got questions (if it exists)
    span {
        font-size: 1.9rem;   
    }
    // Phone number text
    em {
        font-style: normal;
        font-weight: bold;
        font-size: 2.5rem;
        letter-spacing: .06em;
        font-family: $headings-font;   
    }
} 