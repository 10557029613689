// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    border-radius: 0px;
    margin-bottom: 12px;
    font-size: 2.1rem;
    line-height: 1;
    letter-spacing: 0px;
    padding: 24px 10px;
    white-space: normal;
    background: $primary;
    color: #010101;
    font-family: $headings-font;
    transition: background-color .4s ease-in-out;

    @include media-breakpoint-up(md) {
        font-size: 2.3rem;
        padding: 35px 15px;
    }

    &:hover,
    &:focus,
    &:active {
        color: #010101;
        background: rgba($primary, .8);
    }
}

.btn-section {
    max-width: 500px;
    margin: 0 auto;

    .btn{
        margin:0;
    }
}