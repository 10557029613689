// --------------------
// VARIABLES
//---------------------

// Colors
$body-color		: #000000;
$primary        : #d5bf85;
$secondary      : #333;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
$light:         #fcfbf8;
$dark:          #010101;

$bg-table-active-color : #42c7b3;

$slider-title-color : #ffffff;
$slick-dot-color : #ffffff;
$slick-active-dot-color : #42c7b3;

// --------------------
// Container Options
//---------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  );
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
  xl: 1170px,
  xxl: 1600px,
);
