form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 20px 15px 20px 50px;
        box-shadow: none;
        border: 1px solid #ececec;
        margin-bottom: 15px;
        border-radius: 0px;
        font-size: 1.95rem;
        line-height: 1;

        @include media-breakpoint-up(md) {
            padding: 20px 15px 20px 70px;
            height: 90px;
        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
    }

    textarea {
        height: 190px !important;

        @include media-breakpoint-up(md) {
            height: 245px !important;
            padding: 33px 15px 30px 70px;
        }
    }

    select {
        appearance: none;
    }

    label {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        &.error {
            display: block;
            margin-bottom: 15px;
            margin-top: -5px;
            color: red;
            font-size: 1.7rem;
        }
        .icon {
            position: absolute;
            top: 23px;
            left: 17px;
            width: 20px;
            height: 17px;
            z-index: 999;
            margin: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
           
            @include media-breakpoint-up(md) {
                top: 35px;
                left: 30px;
                width: 25px;
                height: 19px;
            }

            &.icon-name {
                background-image: url('../images/icon-user-cover.png');
            }
            &.icon-email {
                background-image: url('../images/icon-email-cover.png');
            }
            &.icon-phone {
                background-image: url('../images/icon-phone-cover.png');
            }
            &.icon-message {
                background-image: url('../images/icon-comment-cover.png');
            }
        }
    }
}

.form-group {
    width: 100%;
    display: block;
    margin:0;
}